<template>
	<div id="app">
		<div
			class="background"
			style="max-width: 400px; display: block; margin: auto; margin-top: 100px; padding: 20px"
		>
			<img
				style="border-radius: 50%; display: block; margin: auto; height: 80px; margin-bottom: 40px"
				src="https://media.licdn.com/dms/image/D4E0BAQF42vtmInTDxw/company-logo_200_200/0/1684839805538?e=2147483647&v=beta&t=JqP7SwYO7mwiy2uXuZDNyXCYQiO5ovgmu8D0d4yhSTo"
			/>
			<h1>Conversational AI for Mobile UI</h1>
			<p>Receive a call from our conversational AI that can sit inside your mobile or web app.</p>
			<el-form label-position="top" label-width="100px">
				<el-form-item label="First name">
					<el-input placeholder="John" v-model="name"></el-input>
				</el-form-item>
				<el-form-item label="Mobile Number">
					<el-input v-model="number">
						<el-select
							style="width: 120px"
							v-model="country"
							slot="prepend"
							placeholder="Select country code"
						>
							<el-option label="+355 - Albania" value="355"></el-option>
							<el-option label="+376 - Andorra" value="376"></el-option>
							<el-option label="+43 - Austria" value="43"></el-option>
							<el-option label="+375 - Belarus" value="375"></el-option>
							<el-option label="+32 - Belgium" value="32"></el-option>
							<el-option label="+387 - Bosnia and Herzegovina" value="387"></el-option>
							<el-option label="+359 - Bulgaria" value="359"></el-option>
							<el-option label="+385 - Croatia" value="385"></el-option>
							<el-option label="+357 - Cyprus" value="357"></el-option>
							<el-option label="+420 - Czech Republic" value="420"></el-option>
							<el-option label="+45 - Denmark" value="45"></el-option>
							<el-option label="+372 - Estonia" value="372"></el-option>
							<el-option label="+358 - Finland" value="358"></el-option>
							<el-option label="+33 - France" value="33"></el-option>
							<el-option label="+49 - Germany" value="49"></el-option>
							<el-option label="+30 - Greece" value="30"></el-option>
							<el-option label="+36 - Hungary" value="36"></el-option>
							<el-option label="+354 - Iceland" value="354"></el-option>
							<el-option label="+353 - Ireland" value="353"></el-option>
							<el-option label="+39 - Italy" value="39"></el-option>
							<el-option label="+383 - Kosovo" value="383"></el-option>
							<el-option label="+371 - Latvia" value="371"></el-option>
							<el-option label="+423 - Liechtenstein" value="423"></el-option>
							<el-option label="+370 - Lithuania" value="370"></el-option>
							<el-option label="+352 - Luxembourg" value="352"></el-option>
							<el-option label="+356 - Malta" value="356"></el-option>
							<el-option label="+373 - Moldova" value="373"></el-option>
							<el-option label="+377 - Monaco" value="377"></el-option>
							<el-option label="+382 - Montenegro" value="382"></el-option>
							<el-option label="+31 - Netherlands" value="31"></el-option>
							<el-option label="+389 - North Macedonia" value="389"></el-option>
							<el-option label="+47 - Norway" value="47"></el-option>
							<el-option label="+48 - Poland" value="48"></el-option>
							<el-option label="+351 - Portugal" value="351"></el-option>
							<el-option label="+40 - Romania" value="40"></el-option>
							<el-option label="+7 - Russia" value="7"></el-option>
							<el-option label="+378 - San Marino" value="378"></el-option>
							<el-option label="+381 - Serbia" value="381"></el-option>
							<el-option label="+421 - Slovakia" value="421"></el-option>
							<el-option label="+386 - Slovenia" value="386"></el-option>
							<el-option label="+34 - Spain" value="34"></el-option>
							<el-option label="+46 - Sweden" value="46"></el-option>
							<el-option label="+41 - Switzerland" value="41"></el-option>
							<el-option label="+380 - Ukraine" value="380"></el-option>
							<el-option label="+44 - United Kingdom" value="44"></el-option>
						</el-select>
					</el-input>
					<p>* We do not store your number</p>
				</el-form-item>
				<el-form-item>
					<el-button :loading="loading" :disabled="!name || !number" type="primary" @click="call()"
						>Receive My Call</el-button
					>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	name: "App",
	components: {},
	data() {
		return {
			name: "",
			number: "",
			loading: false,
			country: "44",
		};
	},
	methods: {
		call() {
			let $this = this;
			this.loading = true;
			axios
				.post(
					"https://platform.tovie.ai/api/calls/campaign/1000099.207449.cfyrVJD7HEkS88QFhCohZN2oGwhodBXKdksHFgurCW8/addPhones",
					[
						{
							phone: this.country + this.number,
							payload: { name: this.name },
						},
					]
				)
				.then(function () {
					$this.loading = false;
					$this.$notify.success({
						title: "Success",
						message: "You will shortly receive a call",
					});
				})
				.catch(function () {
					$this.loading = false;
					$this.$notify.error({
						title: "Error",
						message: "An error ocurred",
					});
				});
		},
	},
};
</script>

<style>
body {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.background {
	background-color: white;
}
</style>
